(function($) {
$(window).on('load', function() {
  // Anchor position after the page load
  var subSectionHashNoslash = window.location.hash;
  // accounting for slash added by shade router
  var subSectionHash = decodeURIComponent(subSectionHashNoslash.toString().split('\/').join('_'))
  var subSectionNospace = subSectionHash.replace(/[' '(/]/g, '');

  if (!!subSectionNospace && $(subSectionNospace).length) {
    var fixedBarHeight = $('.js-site-header-sticky').height();
    if (!isNaN(fixedBarHeight)) {
      $('html, body').animate({
        'scrollTop': $(subSectionNospace).offset().top - fixedBarHeight
      }, 1000);
    }
  }
});
})(jQuery);
